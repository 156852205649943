@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Thin.ttf);
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-ExtraLight.ttf);
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Light.ttf);
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Bold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-ExtraBold.ttf);
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Black.ttf);
  font-weight: 900;
  font-display: swap;
}

* {
  cursor: default !important;
}

/* Layouts */
.drag-top-area {
  -webkit-app-region: drag;
}

.authentication-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

#authentication-container {
  max-width: 100vw;
}

#main-container {
  max-width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* Components */
.router-link {
  color: #2a67f4;
}

.pulsing-overlay {
  transform: scale(1);
  animation: pulse 2s infinite;
}

.habitify-logo {
  margin: 0 auto;
}

.journal-content {
  min-height: 100vh;
  padding-top: 56px;
  margin-left: 270px;
  min-width: 375px;
  max-width: 600px;
  z-index: 1;
}

.single-progress-content {
  position: relative;
  top: 0;
  right: 0;
  min-height: 100vh;
  min-width: 375px;
  z-index: 2;
  width: 100%;
}

.journal-top-bar {
  min-width: inherit;
  max-width: inherit;
  padding: 14.5px 8px 14.5px 8px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: inherit;
  min-height: 56px;
  z-index: 2;
  top: 0;
  overflow-x: hidden;
}

.active-nav-link {
  color: white;
  background-color: #2a67f4;
}

.chakra-ui-light .sidebar .active-nav-link {
  background-color: #2a67f4 !important;
  color: white;
}

.chakra-ui-dark .sidebar .active-nav-link {
  background-color: #3662cc !important;
  color: white;
}

.chakra-ui-light .sidebar .active-nav-link:hover > div {
  background-color: #2a67f4 !important;
}

.chakra-ui-light .sidebar .active-nav-link:hover div p {
  color: white !important;
}

.chakra-ui-light .sidebar .active-nav-link:hover svg path {
  fill: white !important;
}

.chakra-ui-dark .sidebar .active-nav-link:hover > div {
  background-color: #3662cc !important;
}

.date-input {
  border-bottom: 1px solid #e2e8f0;
}

.custom-start-date {
  width: 100%;
}

/* Time keeper css light mode */
.chakra-ui-light .react-timekeeper__top-bar {
  background-color: #2a67f4 !important;
}

.chakra-ui-light .react-timekeeper__tb-minute-wrapper span,
.chakra-ui-light .react-timekeeper__tb-hour-wrapper span,
.chakra-ui-light .react-timekeeper__tb-colon,
.chakra-ui-light .react-timekeeper__tb-meridiem {
  color: #fff;
}

.chakra-ui-light .react-timekeeper__clock {
  background-color: #e0e0e0;
}

.chakra-ui-light .react-timekeeper__clock-hand,
.chakra-ui-light .react-timekeeper__hand-circle-outer,
.chakra-ui-light .react-timekeeper__hand-circle-center {
  fill: #2a67f4;
  stroke: #2a67f4;
}

.chakra-ui-light .react-timekeeper__clock-hours span {
  color: #000000de;
}

.chakra-ui-light .react-timekeeper__meridiem-toggle,
.chakra-ui-light .react-timekeeper__clock-wrapper {
  background: unset;
}

.chakra-ui-light .react-timekeeper__meridiem--active {
  color: #2a67f4;
}

/* Time keeper css dark mode */
.chakra-ui-dark .react-timekeeper__top-bar {
  background-color: #2a67f4 !important;
}

.chakra-ui-dark .react-timekeeper__tb-minute-wrapper span,
.chakra-ui-dark .react-timekeeper__tb-hour-wrapper span,
.chakra-ui-dark .react-timekeeper__tb-colon,
.chakra-ui-dark .react-timekeeper__tb-meridiem {
  color: #fff;
}

.chakra-ui-dark .react-timekeeper__clock {
  background-color: #616161;
}

.chakra-ui-dark .react-timekeeper__clock-hand,
.chakra-ui-dark .react-timekeeper__hand-circle-outer,
.chakra-ui-dark .react-timekeeper__hand-circle-center {
  fill: #2a67f4;
  stroke: #2a67f4;
}

.chakra-ui-dark .react-timekeeper__clock-hours span {
  color: #fff;
}

.chakra-ui-dark .react-timekeeper__meridiem-toggle {
  background: unset;
}

.chakra-ui-dark .react-timekeeper__clock-wrapper {
  background: #424242;
}

.chakra-ui-dark .react-timekeeper__meridiem--active {
  color: #2a67f4;
}

/* Date picker */
.DayPicker-Day {
  width: 40px !important;
  height: 40px !important;
}

.DayPicker-Day--today {
  color: #477eff !important;
}

.DayPicker-Day:hover {
  background-color: #477eff14 !important;
}

.DayPicker-Day:focus {
  outline: none;
}

.DayPicker-Day--selected {
  color: #ffffff !important;
  background-color: #477eff !important;
  color: #fff !important;
}

.DayPicker-Day--selected:hover {
  background-color: rgb(70, 123, 248) !important;
}

.DayPicker-Day--disabled:hover {
  background-color: transparent !important;
}

/* scroll bar */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

.infinite-scroll__log-history::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
/* .chakra-ui-dark ::-webkit-scrollbar-track {
  background: #171717;
} */

.chakra-ui-dark ::-webkit-scrollbar-thumb {
  background: #424242;
  border-radius: 10px;
}

.chakra-ui-light ::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
}

.chakra-ui-dark ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chakra-ui-light ::-webkit-scrollbar-thumb:hover {
  background: #ececec;
}

::-webkit-scrollbar-track-piece {
  background: transparent;
}

/* Keyframes */
@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

/* apex-chart */
.apexcharts-tooltip {
  top: -24px !important;
  border: unset !important;
  overflow: hidden !important;
  background-color: transparent !important;
  box-shadow: unset !important;
}

.apexcharts-tooltip_arrow-box {
  padding: 8px 10px;
  /* background-color: #2a67f4; */
  border-radius: 3px;
  color: white;
}

.apexcharts-tooltip_sub-arrow-box {
  width: 2px;
  /* background-color: #2a67f4; */
  height: 500px;
  position: absolute;
  top: 38px;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}

.apexcharts-xaxistooltip {
  opacity: 0 !important;
  background-color: transparent;
}

.apexcharts-xaxistooltip:after {
  color: red !important;
}

.apexcharts-series path {
  filter: unset !important;
}

.apexcharts-bar-area {
  filter: unset !important;
}

.apexcharts-marker {
  display: none;
}

.chakra-link {
  text-decoration: none;
}

/* screen authenticate */
.btn-authenticate--height {
  height: 47px !important;
}

.chakra-ui-dark input:-webkit-autofill {
  box-shadow: 0 0 0 30px black inset !important;
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff !important;
  border: none !important;
}

.chakra-ui-light input:-webkit-autofill {
  box-shadow: 0 0 0 30px #f5f5f5 inset !important;
  -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000 !important;
  border: none !important;
}

body {
  overflow: hidden;
}

.menu-magic-overlay {
  width: 350px;
  height: 270px;
  background-color: transparent;
  opacity: 0.2;
  position: absolute;
  top: -221px;
  left: -302px;
}

/* custom apple sign in  */
#appleid-signin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

#appleid-signin img {
  opacity: 0;
}

.empty-state--scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

/* .empty-state--scroll::-webkit-scrollbar {
  width: 12px;
	background-color: #F5F5F5;
} */

.empty-state--scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.progress-journal .chakra-progress .chakra-progress__indicator {
  fill: unset;
}

.progress-journal .chakra-progress .chakra-progress__track {
  fill: unset;
  stroke: unset;
}

.completed-habit-icon .chakra-progress .chakra-progress__indicator {
  fill: unset;
}

.completed-habit-icon .chakra-progress .chakra-progress__track {
  fill: unset;
  stroke: unset;
}

.habit-action path {
  fill: unset;
  stroke: unset;
}

.habit-skip-new-journal .habit-action path {
  fill: unset;
  stroke: unset;
  opacity: unset;
}

.alphabetical-nav:hover .sub-alphabetical-nav * {
  color: white !important;
  fill: white !important;
}

.habit-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sketch-picker-dark input {
  color: white;
  background-color: #262626;
}

.sketch-picker-dark label {
  color: white !important;
}

/* new sidebar */
.areas-section * {
  cursor: default;
}

.areas-section--width svg {
  width: 17px;
}

.active-nav-link {
  border-radius: 5px;
  display: block;
}

.active-nav-link p {
  color: white;
}

.active-nav-link:hover {
  background-color: unset !important;
}

.active-nav-link svg * {
  fill: white;
}

.chakra-ui-dark .sidebar-item:hover .sidebar-item-icon--color svg * {
  fill: white;
}
.chakra-ui-dark .sidebar-item:hover .sidebar-item-text--color p {
  color: white;
}

.chakra-ui-light .sidebar-item:hover .sidebar-item-text--color p {
  color: black;
}
.chakra-ui-light .sidebar-item:hover .sidebar-item-icon--color svg path {
  fill: black;
}

.chakra-ui-light .sidebar-item:hover .sidebar-item-icon {
  fill: #000;
}

.chakra-ui-dark .sidebar-item:hover .sidebar-item-icon {
  fill: #fff;
}

.manage-habit-dragging-over {
  fill: #7b7c7c;
}

.manage-habit-selected-tab {
  fill: #fff;
}

div[data-rbd-drag-handle-context-id] {
  cursor: auto;
}

@supports (user-select: none) {
  .sidebar * {
    user-select: none;
  }
}

@supports (-moz-user-select: none) {
  .sidebar * {
    -moz-user-select: none;
  }
}

/* state folder selected */
.folder-selected .folder-name {
  color: white;
}

.folder-selected .folder-wrapper-icon svg path {
  fill: white;
}

.folder-selected .folder-wrapper-icon:hover svg path {
  fill: rgba(255, 255, 255, 0.5);
}

/* state folder hover */
.chakra-ui-light .folder-hovered .folder-name {
  color: black;
}
.chakra-ui-light .folder-hovered .folder-wrapper-icon svg path {
  fill: black;
}
.chakra-ui-light .folder-hovered .folder-wrapper-icon:hover svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.chakra-ui-dark .folder-hovered .folder-name {
  color: white;
}
.chakra-ui-dark .folder-hovered .folder-wrapper-icon svg path {
  fill: white;
}
.chakra-ui-dark .folder-hovered .folder-wrapper-icon:hover svg path {
  fill: rgba(255, 255, 255, 0.5);
}
/* state eidtable focus */
.chakra-ui-light .folder-editable-focus input {
  color: black;
}
.chakra-ui-dark .folder-editable-focus input {
  color: rgba(255, 255, 255, 0.95);
}
.folder-editable-focus .folder-wrapper-icon svg path {
  fill: #7b7c7c;
}
.chakra-ui-light .folder-editable-focus .folder-wrapper-icon:hover svg path {
  fill: black;
}
.chakra-ui-dark .folder-editable-focus .folder-wrapper-icon:hover svg path {
  fill: white;
}
/* state highlighted */
.chakra-ui-light .folder-highlighted .folder-name {
  color: black;
}
.chakra-ui-dark .folder-highlighted .folder-name {
  color: rgba(255, 255, 255, 0.95);
}
.folder-highlighted .folder-wrapper-icon svg path {
  fill: #7b7c7c;
}
.chakra-ui-light .folder-highlighted .folder-wrapper-icon:hover svg path {
  fill: black;
}
.chakra-ui-dark .folder-highlighted .folder-wrapper-icon:hover svg path {
  fill: white;
}
.chakra-ui-light .new-folder-wrapper-icon:hover svg path {
  fill: black;
}
.chakra-ui-dark .new-folder-wrapper-icon:hover svg path {
  fill: white;
}
/* end new sidebar */

/* new-icon-picker */
.wrapper-icon-picker:hover svg * {
  fill: #7b7c7c;
}
/* end new-icon-picker */

.chakra-ui-light .profile-avatar svg path {
  fill: #e5e4e5;
}

.chakra-ui-dark .profile-avatar svg path {
  fill: #4a4a4a;
}

/* custom position icon in chakra-ui */
/* .chakra-button span:first-child {
  margin-top: -4px;
}
.chakra-button span:last-child {
  margin-top: -2px;
} */

/* reminder */
.reminder-input {
  height: 22px !important;
}

.reminder-box {
  height: 30px;
}

.reminder-box-extend {
  min-height: 30px;
}

/* Menu item */
.chakra-ui-dark .menu-item-title {
  color: rgb(255, 255, 255, 0.7);
}

.chakra-ui-light .menu-item-title,
.chakra-ui-light .menu-item-title svg {
  color: rgb(0, 0, 0, 0.7);
}

.chakra-ui-dark .menu-item-title svg {
  color: #fffffff2;
}

.menu-item-title:hover {
  color: white;
}

.menu-item-selected {
  color: #ffffff;
}

/* pulldown button */
.pulldown-button span:nth-child(1) {
  margin-right: 0;
}
.pulldown-button span:nth-child(2) {
  display: none;
}

/* timer */
.chakra-ui-light .progress-timer {
  background-color: rgba(42, 103, 244, 0.2) !important;
}

.chakra-ui-light .progress-timer div {
  background-color: #2a67f4;
}

.chakra-ui-dark .progress-timer {
  background-color: rgba(54, 98, 204, 0.2) !important;
}

.chakra-ui-dark .progress-timer div {
  background-color: #3662cc;
}

html,
body {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.log-checked {
  background-color: rgba(42, 103, 244, 0.1);
}

.chakra-ui-light .log-checked .chakra-checkbox__control[data-checked] {
  background-color: #2a67f4 !important;
  border-color: #2a67f4 !important;
}

.chakra-ui-dark .log-checked .chakra-checkbox__control[data-checked] {
  background-color: #3662cc !important;
  border-color: #3662cc !important;
}

.log-normal span {
  box-shadow: unset !important;
  margin-left: 0 !important;
}

.log-normal .chakra-checkbox__control {
  border-width: 1px !important;
  border-radius: 3px !important;
}

.chakra-ui-light .log-normal span {
  border-color: #e5e4e5 !important;
}

.chakra-ui-dark .log-normal span {
  border-color: #4a4a4a !important;
}

.log-normal * {
  cursor: default !important;
}

.resize {
  cursor: ew-resize !important;
}

.sidebar-editable *,
.habit-description-editable * {
  -webkit-user-select: text !important; /* Chrome, Opera, Safari */
  -moz-user-select: text !important; /* Firefox 2+ */
  -ms-user-select: text !important; /* IE 10+ */
  user-select: text !important; /* Standard syntax */
}
.text-area-note-input::placeholder {
  font-size: 15px;
  line-height: 18.15px;
  color: #7b7c7c !important;
}

.apexcharts-canvas {
  width: 100% !important;
}

/* markdown message */
.markdown-message {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 12px;
}
.markdown-message ul li {
  list-style-type: none;
}

.markdown-message img {
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 5px;
}

.slide-message {
  width: 392px !important;
  left: unset !important;
}

.slide-whisper-message {
  position: absolute !important;
}

.add-off-mode > span:nth-child(2) {
  display: none;
}
